.patient-note {
    background-color: var(--alt-background-color);
    border-radius: 10px;
    cursor: pointer;
}

.patient-note:hover, .patient-note.selected {
    background-color: var(--alt-background-color-hover);
}

.full-screen {
    overflow-y: auto;
    height: calc(100vh - 75px);
}