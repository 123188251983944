html {
  --bs-dark-rgb: 66, 68, 76;
}

:root,
html[data-theme='light'] {
  --background-color: rgb(241, 245, 248);
  --alt-background-color: rgb(220, 220, 220);
  --alt-background-color-hover: rgb(200, 200, 200);
  --text-color: #42444c;
  --bp-color: #9019ff;
  --bp-selected: #9019ff;
  --bp-color-rgb: 144, 25, 255;
  --theme: light;

  /* Keep bootstrap classes */
  --bp-dark-rgb: 66, 68, 76;
  --bp-light-rgb: 235, 235, 235;
  --bp-darker-light-rgb: 255, 255, 255;
  --bp-light-blue: #d2d9e0;
  --bp-border: rgba(130, 130, 133, 0.3);
}

html[data-theme='dark'] {
  --background-color: #2c3333;
  --alt-background-color: rgb(64, 74, 74);
  --alt-background-color-hover: rgb(52, 58, 64);
  --text-color: #eee;
  --bp-color: #00e7d1;
  --bp-selected: #ce99ff;
  --bp-color-rgb: 0, 231, 209;
  --theme: dark;

  /* Inverse bootstrap classes */
  --bp-dark-rgb: var(--bs-light-rgb);
  --bp-light-rgb: var(--bs-dark-rgb);
  --bp-darker-light-rgb: var(--bs-dark-rgb);
  --bp-light-blue: #aebcc7;
  --bp-border: rgba(218, 220, 224, 0.3);
}

* {
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 250ms linear;
  transition: background-color 250ms linear;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.modal-content {
  background-color: var(--background-color);
}

.modal-footer {
  border-top: var(--bs-modal-footer-border-width) solid #c6c6c6;
}

.required {
  color: #6c757d;
  font-weight: bold;
}

a.text-light:hover {
  color: #00e7d1 !important;
}

a.text-light.selected {
  color: #ce99ff !important;
}

a.bp-text-dark:hover {
  color: #00e7d1 !important;
}

a.bp-text-dark.selected {
  color: var(--bp-selected) !important;
}

.bp-bg-dark {
  --bs-bg-opacity: 1;
  --bs-text-opacity: 1;
  background-color: rgba(var(--bp-dark-rgb), var(--bs-bg-opacity)) !important;
  color: rgba(var(--bp-light-rgb), var(--bs-text-opacity));
}

.bp-bg-light {
  --bs-bg-opacity: 1;
  --bs-text-opacity: 1;
  background-color: rgba(var(--bp-light-rgb), var(--bs-bg-opacity)) !important;
  color: rgba(var(--bp-dark-rgb), var(--bs-text-opacity));
}

.bp-darker-bg-light {
  --bs-bg-opacity: 1;
  --bs-text-opacity: 1;
  background-color: rgba(
    var(--bp-darker-light-rgb),
    var(--bs-bg-opacity)
  ) !important;
  color: rgba(var(--bp-dark-rgb), var(--bs-text-opacity));
}

.bp-bg-light-blue {
  --bs-text-opacity: 1;
  background-color: var(--bp-light-blue) !important;
}

.bp-text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bp-dark-rgb), var(--bs-text-opacity)) !important;
}

.bp-text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bp-light-rgb), var(--bs-text-opacity)) !important;
}

.bp-btn {
  width: 100%;
  border-radius: 23px;
  background-color: #9019ff;
  color: white;
  border-width: 0px;
  font-size: 1.1rem;
}

.btn-no-bg {
  background-color: transparent;
  border: 1px solid var(--bp-border);
  color: rgba(var(--bp-light-rgb), var(--bs-text-opacity)) !important;
}

.btn-no-bg:hover,
.btn-no-bg:focus {
  background-color: rgba(218, 220, 224, 0.1);
  border: 1px solid var(--bp-border);
}

.btn-empty {
  background-color: transparent;
  color: rgba(var(--bp-light-rgb), var(--bs-text-opacity)) !important;
  border: none;
  border-radius: 50%;
}

.btn-empty:hover,
.btn-empty:focus {
  background-color: rgba(218, 220, 224, 0.1);
}

.btn-action {
  background-color: #9019ff;
  border-color: #9019ff;
}

.btn-action:disabled {
  background-color: #602e8f;
  border-color: #602e8f;
  opacity: 0.5;
}


.bp-btn:hover,
.bp-btn:focus,
.btn-action:hover,
.btn-action:focus {
  background-color: #a748ff;
  border-color: #a748ff;
}

.bp-btn:disabled {
  background-color: #9019ff;
  border-color: #9019ff;
  opacity: 0.5;
}

.btn-dark {
  background-color: var(--alt-background-color);
  border-color: var(--alt-background-color);
}

.btn-dark:hover,
.btn-dark:focus {
  background-color: #5a5c67;
  border-color: #5a5c67;
}

.btn-modify {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-secondary-action {
  background-color: #f1bf26;
  border-color: #f1bf26;
  color: rgb(52, 58, 64);
}

.btn-secondary-action:hover,
.btn-secondary-action:focus {
  background-color: #d3a312;
  border-color: #d3a312;
  color: rgb(52, 58, 64);
}

.btn-secondary-action:disabled {
  background-color: #f1bf26 !important;
  border-color: #f1bf26 !important;
  color: rgb(52, 58, 64) !important;
  opacity: 0.5;
}

.btn-modify:hover,
.btn-modify:focus,
.btn-modify:disabled {
  background-color: #ce8c2f;
  border-color: #ce8c2f;
}

.emphasize-on-hover {
  opacity: 0.5;
}

.emphasize-on-hover:disabled {
  opacity: 0.15;
}

.emphasize-on-hover:hover:not(:disabled) {
  opacity: 1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00e7d1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #9019ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #9019ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar {
  width: 8px;
}

textarea::-webkit-scrollbar,
select::-webkit-scrollbar,
.my-scroll::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track,
select::-webkit-scrollbar-track,
.my-scroll::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background-color: rgba(var(--bp-dark-rgb), 0.05);
}

body::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
select::-webkit-scrollbar-thumb,
.my-scroll::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: rgba(var(--bp-dark-rgb), 0.75);
  border-radius: 12px;
}

body::-webkit-scrollbar-thumb:hover,
textarea::-webkit-scrollbar-thumb:hover,
select::-webkit-scrollbar-thumb:hover,
.my-scroll::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(var(--bp-dark-rgb), 0.9);
}

/* MATERIAL INPUT */

.group {
  position: relative;
}

textarea {
  resize: none;
}

input:not([type='checkbox']),
textarea {
  background: none;
  color: var(--text-color);
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
}

input:not([type='checkbox']):focus,
textarea:focus {
  outline: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0);
  opacity: 0; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0);
}

textarea {
  min-height: 50px;
  height: 50px;
  max-height: 200px;
  resize: vertical;
}

input:not([type='checkbox']),
textarea {
  margin-top: 7px;
}

input:not([type='checkbox']):focus ~ label,
textarea:focus ~ label,
input:not([type='checkbox']):not(:placeholder-shown) ~ label,
textarea:not(:placeholder-shown) ~ label {
  top: -7px;
  font-size: 12px;
  color: var(--bp-color);
}

input:not([type='checkbox']):focus ~ .bar:before,
textarea:focus ~ .bar:before {
  width: 100%;
}

input[type='password'] {
  letter-spacing: 0.3em;
}

input[type='datetime-local'] {
  color-scheme: var(--theme);
}

label:not(.checkbox):not(.switch) {
  color: #c6c6c6;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}

.bar {
  position: relative;
  display: block;
  /*width: 100%;*/
}

.bar:before {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: var(--bp-color);
  transition: 300ms ease all;
  left: 0%;
}

/* HIDE NUMBER ARROWS */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* SELECT */

.select {
  position: relative;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  padding: 10px 10px 10px 0;
  font-size: 18px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c6c6c6;
}

/* Remove focus */
.select-text:focus {
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

/* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance: none;
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--text-color);
  pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
  color: rgba(0, 0, 0, 0.26);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
}

/* active state */
.select-text:focus ~ .select-label,
.select-text.option-selected ~ .select-label {
  color: var(--bp-color);
  left: 0px;
  top: -14px;
  transition: 0.2s ease all;
  font-size: 12px;
}

/* BOTTOM BARS ================================= */
.select-bar {
  position: relative;
  display: block;
}

.select-bar:before,
.select-bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--bp-color);
  transition: 0.2s ease all;
}

/* active state */
.select-text:focus ~ .select-bar:before,
.select-text:focus ~ .select-bar:after {
  width: 100%;
}

/* HIGHLIGHTER ================================== */
.select-highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

select:active {
  background-image: linear-gradient(
      var(--background-color),
      var(--background-color)
    ),
    linear-gradient(-135deg, transparent 50%, var(--background-color) 50%),
    linear-gradient(-225deg, transparent 50%, var(--background-color) 50%),
    linear-gradient(var(--background-color) 42%, #fff 42%);
  color: var(--text-color) !important;
  border-color: var(--background-color);
  background-color: var(--background-color);
}

/* CHECKBOX */

.pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
}

/* Input */
.pure-material-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Box */
.pure-material-checkbox > span::before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: var(--text-color);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.pure-material-checkbox > span::after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 2px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.pure-material-checkbox:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pure-material-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
  border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
}

/* Disabled */
.pure-material-checkbox > input:disabled {
  opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}

.notification-container {
  margin-top: 50px;
}

.all-vertical {
  height: calc(100vh - 120px);
}

.partial-vertical {
  height: calc(20vh - 24px);
}

.partial-vertical.small {
  height: calc(16.66vh - 24px);
}

.flex-grid {
  display: flex;
}

.col-grid {
  flex: 1;
}

.calendar-cell {
  border-top: 1px solid var(--bp-border);
  border-left: 1px solid var(--bp-border);
  overflow-y: auto;
}

.calendar-cell.right {
  border-right: 1px solid var(--bp-border);
}

.calendar-cell.bottom {
  border-bottom: 1px solid var(--bp-border);
}

.calendar-cell.top.left {
  border-top-left-radius: 10px;
}

.calendar-cell.top.right {
  border-top-right-radius: 10px;
}

.calendar-cell.bottom.left {
  border-bottom-left-radius: 10px;
}

.calendar-cell.bottom.right {
  border-bottom-right-radius: 10px;
}

.today {
  background-color: var(--bp-color);
  color: rgb(var(--bp-light-rgb));
  border-radius: 50%;
  padding: 4px;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 12px;
}

.primary-color {
  color: var(--bp-color);
}

.calendar-session {
  font-size: 0.7em;
  text-align: left;
  cursor: pointer;
  transition: border-radius 0s, padding-left 0.4s, margin-left 0.4s,
    padding-right 0.4s, margin-right 0.4s;
}

.calendar-session:hover {
  background-color: rgba(var(--bp-darker-light-rgb), 1);
  border-radius: 10px;
  padding-left: 6px;
  margin-left: -6px;
  padding-right: 5px;
  margin-right: -5px;
}

.calendar-vacation {
  color: rgb(var(--bp-light-rgb));
  background-color: var(--bp-color) !important;
  border-radius: 10px;
  padding-left: 6px;
  margin-left: -6px;
  padding-right: 5px;
  margin-right: -5px;
}

.calendar-vacation:hover {
  color: rgb(var(--bp-dark-rgb));
  background-color: var(--bp-border) !important;
}

.all-day-sessions {
  margin: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
}

#tv-attr-logo {
  display: none;
}

/* rotate indefinitely */
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate-on-hover:hover {
  animation: rotation 2s infinite linear;
}
