.ck-toolbar {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: var(--alt-background-color) !important;
  border: 1px solid var(--alt-background-color) !important;
}

.ck-button {
  background-color: var(--alt-background-color) !important;
  color: var(--text-color) !important;
}

.ck-dropdown__panel {
  background-color: var(--alt-background-color-hover) !important;
  border-width: 0px !important;
}

.ck-link-form {
  background-color: var(--alt-background-color) !important;
}

.ck-input-text {
  background-color: var(--nextbrain-sidebar-color) !important;
  border-color: var(--nextbrain-secondary-border-color) !important;
  color: var(--text-color) !important;
  border-radius: 5px !important;
}

.ck-balloon-panel {
  border-width: 0px !important;
}

.ck-insert-table-dropdown__label {
  color: var(--text-color) !important;
}

.ck-button:hover {
  background-color: var(--alt-background-color-hover) !important;
}

.ck-content {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background-color: var(--alt-background-color-hover) !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: var(--alt-background-color-hover);
  border: 1px solid var(--alt-background-color) !important;
}

.ck.ck-toolbar {
  border: 0px !important;
}

.ck-table-bogus-paragraph, .table {
  color: var(--text-color) !important;
}

.table {
  border-left-width: 1px !important;
}

td {
  width: 10%;
  text-align: center;
}

table, tbody, tr {
  width: 100% !important;
}

.note-dark-border {
  border: 1px solid #464c54;
}
